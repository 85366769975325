.mark {
  width: 10px;
  height: 5px;
  background: black;
  display: inline-block;
}

.label {
  display: block;
  margin-top: -5px;
  margin-bottom: 5px;
}

.color {
  background:#eee;
  border: 2px solid white;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  border-radius: 50%;
  width: 22px;
  height: 22px;
  cursor: pointer;
  display: inline-block;
}

.root {
  position: absolute;
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  text-align: center;
  padding-top:13px;
}

.root:hover .remove {
  transform: scale(1);
}

.remove {
  position: absolute;
  transform: scale(0);
  transition: transform 200ms;
  cursor: pointer;
  left: 50%;
  margin-left: -10px;
  top: 0;
  background:white;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.add {
  position: absolute;
  transform: scale(0);
  transition: transform 200ms;
  cursor: pointer;
  margin-left: -10px;
}

.image:hover .add {
  transform: scale(1);
}
