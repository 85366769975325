html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
input,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

b,
strong {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
}

html {
  line-height: 1.15;
  text-size-adjust: 100%;
  font-size: 16px;
}

body {
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 0.9rem;
  line-height: 1.3;
  color: #444;
}

h1 {
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 1;
  margin-bottom: 1.4rem;
}

p {
  margin-bottom: 1rem;
}

a {
  color: #005ba0;
}

.form {
  margin-bottom: 20px;
}

.form .input-row {
  margin-bottom: 12px;
}

.form .input-row label {
  vertical-align: middle;
  font-size: 0.87rem;
  color: #333;
}

.form .input-row label small {
  font-size: 0.85rem;
}

.form .input-row .input-label {
  display: block;
}

.form .input-row.has-error label {
  color: #c82333;
}

.form .input-row input[type='email'],
.form .input-row input[type='password'] {
  display: block;
  width: 100%;
  color: #444;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  font-size: 0.9rem;
  font-family: Arial, sans-serif;
  margin: 3px 0;
}

.form .input-row input[type='checkbox'] {
  vertical-align: middle;
  margin-right: 6px;
}

.form .input-row.has-error input[type='email'],
.form .input-row.has-error input[type='password'] {
  border-color: #c82333;
  color: #c82333;
}

.form .button-row {
  text-align: center;
}

.form .button-row button {
  display: inline-block;
  border: none;
  padding: 10px 18px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.form .button-row button:disabled {
  cursor: wait;
}

.form .button-row button[type='submit'] {
  background: linear-gradient(to bottom, #ad0090, #af0061);
  color: #fff;
}

.form .button-row button[type='submit']:disabled {
  background: linear-gradient(to bottom, #85026f, #8c205c);
}

.form .input-error {
  margin-top: 4px;
  font-size: 0.8rem;
  color: #c82333;
}
