.layout {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.dialog {
  position: absolute;
  bottom: 0;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.3);
  z-index: 3;
  background: white;
  transition: all 250ms;
}

.map {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
}

.container {
  position: absolute;
  max-width: 100vw;
  left: 0;
  top: 0;
  padding: 1rem 0 10px;
  z-index: 2;
  overflow: visible;
}

.tools {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
  background: white;
  display: inline-block;
}

@media (max-width: 767px) {
  .dialog {
    width: 100vw;
  }
  .container {
    overflow: auto visible;
  }
}
